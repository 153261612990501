// cursor pointer
.cursor {
    @media (max-width: 720px) {
        display: none;
    }
}

@media (any-pointer: fine) {
    .cursor {
        position: fixed;
        top: 0;
        left: 0;
        display: block;
        pointer-events: none;
        z-index: 400;
    }

    .cursor__inner {
        fill: var(--cursor-fill);
        stroke: var(--cursor-stroke);
        stroke-width: var(--cursor-stroke-width);
    }

    .credits {
        padding-left: 25vw;
    }
}